import * as React from 'react';
import { Box } from '@mui/material';
import { MainActionButton } from '../../../../components/actionButton/MainActionButton';
import AddIcon from '@mui/icons-material/Add';
import styles from '../../AdminPage.module.scss'
import { useAdminPageProxyData } from '../../../../utils/redux/storeHooks/dataHooks/useAdminPageProxyData';
import { ProxyPageType } from '../../../../types/pages/adminPage/proxy';
import { usePopupActions } from '../../../../utils/redux/storeHooks/actionsHooks/usePopupActions';

export const ProxiesMonitoringActions = () => {
  const { proxyPageType } = useAdminPageProxyData();
  const { setPopUpAddNewDefaultProxyOpen } = usePopupActions();

  const openPopUpAddNewDefaultProxy = () => {
    setPopUpAddNewDefaultProxyOpen(true)
  }

  return (
    <Box className={styles.monitoringProxies__actionsContainer}>
      {proxyPageType === ProxyPageType.DEFAULTPROXIES && <MainActionButton
        title='Add new'
        icon={<AddIcon />}
        iconPosition='left'
        actionOnClick={openPopUpAddNewDefaultProxy}
      />}
    </Box>
  );
}
