import * as React from 'react';
import { Avatar, Box, IconButton, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { TableLoader } from '../../../../../components/tableLoader/TableLoader';
import { usePopupActions } from '../../../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { useAdminPageProxyData } from '../../../../../utils/redux/storeHooks/dataHooks/useAdminPageProxyData';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import SettingsIcon from '@mui/icons-material/Settings';
import { MonitoringUsersProxyEntity } from '../../../../../types/pages/adminPage/proxy';
import { useAdminPageProxyActions } from '../../../../../utils/redux/storeHooks/actionsHooks/useAdminPageProxyActions';

export const TableUserProxiesBody = () => {
  const { proxies, isGetUsersProxiesRequest } = useAdminPageProxyData();
  const { setManageCurrentUserProxy } = useAdminPageProxyActions();
  const { setPopUpManageUserProxyOpen } = usePopupActions();

  const onOpenPopUpManageUserProxy = (proxyData: MonitoringUsersProxyEntity) => {
    setPopUpManageUserProxyOpen(true);
    setManageCurrentUserProxy(proxyData);
  };

  return (
    <TableBody sx={{ position: 'relative', color: '#3B2470' }}>
      {isGetUsersProxiesRequest && proxies.length > 0 && <TableLoader />}
      {proxies.map((proxyData) => {
        const user = proxyData.user;
        const proxy = proxyData.proxy;
        return (
          <TableRow
            hover
            key={proxy.id}
            sx={{
              maxHeight: '64px'
            }}
          >
            <TableCell sx={{ minWidth: 150, maxWidth: 150, padding: '12px 12px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', fontFamily: 'Kanit' }}>
                <Avatar
                  alt={user.firstName}
                  src={user.profileImg}
                  sx={{ width: '40px', height: '40px' }}
                />
                {`${user.firstName} ${user.lastName}`}
              </Box>
            </TableCell>

            <TableCell
              sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}
              align="center">
              {user.countryCodeByIp}
            </TableCell>

            <TableCell
              sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}
              align="center">
              {user.proxyFlow}
            </TableCell>

            <TableCell
              sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}
              align="center">
              {proxy.proxy.split('@').pop()}
            </TableCell>

            <TableCell
              sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}
              align="center">
              {proxy.country_code || '-'}
            </TableCell>

            <TableCell
              sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}
              align="center">
              {proxy.isValid
                ? <CheckBoxIcon sx={{ color: 'rgba(191, 232, 177, 1)' }} />
                : <DisabledByDefaultIcon sx={{ color: 'rgba(255, 183, 163, 1)' }} />}
            </TableCell>

            <TableCell
              sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}
              align="center">
              {proxy.isUseForTwoOrMoreUsers && proxy.proxy !== 'none'
                ? (
                  <Typography sx={{ color: 'rgba(104, 23, 0, 1)', fontWeight: 600 }}>
                    shared
                  </Typography>)
                : (
                  <Typography sx={{ color: 'rgba(37, 102, 14, 1)' }}>
                    {proxy.proxy === 'none' ? '-' : 'one'}
                  </Typography>)}
            </TableCell>

            <TableCell sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }} align="center">
              <div>
                <IconButton color="primary" aria-label="dm" onClick={() => onOpenPopUpManageUserProxy(proxyData)}>
                  <SettingsIcon sx={{ color: '#3B2470' }} />
                </IconButton>
              </div>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  )
}