import React, { useState } from 'react';
import styles from "./ManageDefaultProxy.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Box, Typography } from '@mui/material';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { StyledAutocomplete } from '../../styledAutocomplete/StyledAutocomplete';
import { useAdminPageProxyData } from '../../../utils/redux/storeHooks/dataHooks/useAdminPageProxyData';
import { useAdminPageProxyActions } from '../../../utils/redux/storeHooks/actionsHooks/useAdminPageProxyActions';

export const ManageDefaultProxy = () => {
  const { setPopUpManageDefaultProxyOpen } = usePopupActions();
  const { manageCurrentDefaultProxy, isUpdateDefaultProxyRequest, availableWebShareProxies } = useAdminPageProxyData();
  const { updateDefaultProxy, setManageCurrentDefaultProxy } = useAdminPageProxyActions();
  const [selectedWebshareProxy, setSelectedWebshareProxy] = useState<string>('');

  const closePopUp = () => {
    setPopUpManageDefaultProxyOpen(false)
    setManageCurrentDefaultProxy(null)
  }

  const onUpdateDefaultProxy = () => {
    const ip = selectedWebshareProxy.split(' ')[0]
    const currentProxy = availableWebShareProxies.find(proxy => proxy.proxy.includes(ip))
    if (currentProxy) {
      updateDefaultProxy({
        oldProxyId: manageCurrentDefaultProxy.id,
        newProxyId: currentProxy.id,
        newProxy: currentProxy.proxy,
      })
    }
  }

  const onSelectedWebshareProxy = (newValue: string) => {
    if (newValue) {
      const ip = newValue.split(' ')[0]
      const currentWebshareProxy = availableWebShareProxies.find(proxy => proxy.proxy.includes(ip))
      if (currentWebshareProxy) {
        setSelectedWebshareProxy(newValue)
      }
      return;
    }
    setSelectedWebshareProxy(null)
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Manage default proxy
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>
        <Box className={styles.content__currentProxy}>
          {`Current proxy: ${manageCurrentDefaultProxy?.proxy?.split('@')[1]} ${manageCurrentDefaultProxy?.country_code}`}
        </Box>

        <Box className={styles.content__inputs}>
          <Box className={styles.content__proxyFlow}>
            <Typography className={styles.content__proxyFlow__title}>
              Select available webshare proxy:
            </Typography>
            <StyledAutocomplete
              sx={{ width: '100%', height: '40px' }}
              freeSolo={false}
              onChange={(_, newValue: string | null) => {
                onSelectedWebshareProxy(newValue);
              }}
              options={availableWebShareProxies.map(proxy => `${proxy.proxy.split('@').pop()} ${proxy.country_code}`)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={selectedWebshareProxy}
                  InputProps={{
                    ...params.InputProps,
                    readOnly: true,
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </Box>


      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={closePopUp}
          className={styles.footer__cancel}
        >
          Cancel
        </Button>
        <MainActionButton
          title='Update'
          fontWeight={300}
          actionOnClick={onUpdateDefaultProxy}
          isLoading={isUpdateDefaultProxyRequest}
          isDisabled={isUpdateDefaultProxyRequest || !selectedWebshareProxy}
        />
      </Box>
    </Box>
  );
};
