export const getPageFromTo =  (page: number, perPage: number) => {
  const pageFrom = page === 0 ? page : page * perPage;
  const pageTo = pageFrom + perPage;

  return {pageFrom, pageTo}
}

export const getSkip =  (page: number, perPage: number) => {
  const skip = page === 0 ? page : page * perPage;
  return skip
}