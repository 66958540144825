import React from 'react';
import styles from "./DeleteDefaultProxy.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Box, Typography } from '@mui/material';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { useAdminPageProxyActions } from '../../../utils/redux/storeHooks/actionsHooks/useAdminPageProxyActions';
import { useAdminPageProxyData } from '../../../utils/redux/storeHooks/dataHooks/useAdminPageProxyData';

export const DeleteDefaultProxy = () => {
  const { setPopUpDeleteDefaultProxyOpen } = usePopupActions();
  const { deleteDefaultProxy, setManageCurrentDefaultProxy } = useAdminPageProxyActions();
  const { isDeleteDefaultProxyRequest, manageCurrentDefaultProxy } = useAdminPageProxyData();

  const closePopUp = () => {
    setPopUpDeleteDefaultProxyOpen(false);
    setManageCurrentDefaultProxy(null)
  }

  const confirm = () => {
    deleteDefaultProxy({
      proxyId: manageCurrentDefaultProxy.id
    })
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Delete user
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>
        <Typography className={styles.content__text}>
          Are you sure to delete default proxy?
        </Typography>
      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={closePopUp}
          className={styles.footer__cancel}
        >
          Cancel
        </Button>
        <MainActionButton
          title='Confirm'
          height={40}
          width={80}
          fontWeight={300}
          actionOnClick={confirm}
          isLoading={isDeleteDefaultProxyRequest}
          isDisabled={isDeleteDefaultProxyRequest}
        />
      </Box>
    </Box>
  );
};
