import { HeadCell } from "../../../types/pages/dashboardLeadsPeoplePage/headCell";

export const headCellsDefaultProxies: readonly HeadCell[] = [
  {
    id: 'ProxyIp',
    numeric: false,
    disablePadding: false,
    label: 'Proxy IP',
    disableSorting: false,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 150,
    minWidth: 150,
  },
  {
    id: 'countryCode',
    numeric: false,
    disablePadding: false,
    label: 'Proxy country',
    disableSorting: false,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  },
  {
    id: 'last_verification',
    numeric: true,
    disablePadding: false,
    label: 'Last verification',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 100,
    minWidth: 100,
  },
  {
    id: 'Usage',
    numeric: true,
    disablePadding: false,
    label: 'Usage',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  },
  {
    id: 'isValid',
    numeric: true,
    disablePadding: false,
    label: 'is Valid',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  },
  {
    id: 'setting',
    numeric: true,
    disablePadding: false,
    label: '',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  },
  {
    id: 'delete',
    numeric: true,
    disablePadding: false,
    label: '',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  },

];