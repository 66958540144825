import * as React from 'react';
import { AnimateTab } from '../../../../components/animateTab/AnimateTab';
import { Box, Divider } from '@mui/material';
import { TableUserProxies } from './usersProxiesTable/TableUserProxies';
import { useAdminPageProxyActions } from '../../../../utils/redux/storeHooks/actionsHooks/useAdminPageProxyActions';
import { useEffect } from 'react';
import { TableDefaultProxies } from './defaultProxiesTable/TableDefaultProxies';
import styles from '../../AdminPage.module.scss'
import { ProxyPageType } from '../../../../types/pages/adminPage/proxy';
import { ProxiesMonitoringActions } from './ProxiesMonitoringActions';
import { useAdminPageProxyData } from '../../../../utils/redux/storeHooks/dataHooks/useAdminPageProxyData';

export const ProxiesMonitoring = () => {
  const { getUsersProxyList, getDefaultProxyList, setProxyPageType } = useAdminPageProxyActions()
  const { proxyPageType } = useAdminPageProxyData();

  const changeProxyPageType = (pageType: ProxyPageType) => {
    setProxyPageType(pageType)
  }

  useEffect(() => {
    getUsersProxyList();
    getDefaultProxyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AnimateTab>
      <Box className={styles.monitoringProxies__mainContainer}>
        <Box className={styles.monitoringProxies__pageTypesContainer}>
          {Object.keys(ProxyPageType).map(pageType => {
            const typedPageType = pageType as keyof typeof ProxyPageType;
            return (
              <React.Fragment key={pageType}>
                <Box
                  className={`${styles.monitoringProxies__tableTitle} ${proxyPageType === ProxyPageType[typedPageType] && styles.monitoringProxies__tableTitle_active}`}
                  onClick={() => changeProxyPageType(ProxyPageType[typedPageType])}
                >
                  {ProxyPageType[typedPageType]}
                </Box >
              </React.Fragment>
            )
          })}
        </Box>

        <ProxiesMonitoringActions />
      </Box>
      <Divider />
      {proxyPageType === ProxyPageType.USERPROXIES && <TableUserProxies />}
      {proxyPageType === ProxyPageType.DEFAULTPROXIES && <TableDefaultProxies />}
    </AnimateTab>
  );
}
