import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { useEffect, useRef } from 'react';
import { useAdminPageData } from '../../../../utils/redux/storeHooks/dataHooks/useAdminPageData';
import { AnimateTab } from '../../../../components/animateTab/AnimateTab';
import { LoaderSkeleton } from './LoaderSkeleton';
import { TableUsersHeader } from './TableUsersHeader';
import { TableUsersBody } from './TableUsersBody';
import { useAdminPageActions } from '../../../../utils/redux/storeHooks/actionsHooks/useAdminPageActions';
import { TablePagination } from '@mui/material';

export const TableUsers = () => {
  const { users, isUsersLoading, page, rowsPerPage, usersCount } = useAdminPageData()
  const { setPage, setRowsPerPage } = useAdminPageActions();
  const tableRef = useRef(null);
  const tableContainerRef = useRef(null);

  const scrollUp = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  const handleScroll = () => {
    const tableContainer = tableContainerRef.current;
    if (tableRef.current) {
      const tableRect = tableRef.current.getBoundingClientRect();
      if (tableRect.top <= 20) {
        tableContainer.style.height = `calc(100vh - 70px)`
        return;
      }
      tableContainer.style.height = `100%`
    }
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    scrollUp()
  }, [page, rowsPerPage])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <AnimateTab>
      <Box sx={{ width: '100%', }} >
        <Paper sx={{ width: '100%', boxShadow: 'unset' }}>
          <TableContainer ref={tableContainerRef} sx={{ borderRadius: 2 }}>
            <Table
              ref={tableRef}
              aria-labelledby="tableTitle"
              size={'medium'}
              stickyHeader
            >
              <TableUsersHeader scrollUp={scrollUp} />
              {users?.length === 0 && isUsersLoading && <LoaderSkeleton />}
              <TableUsersBody />
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={usersCount || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => handleChangePage(newPage)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton
            showLastButton
          />
        </Paper>
      </Box >
    </AnimateTab>
  );
}
