import axios, { AxiosError } from "axios";
import Cookies from 'universal-cookie';
import { cleanUpAllStorage, deleteAuthCookies } from "./get_set_user_information";
import { apiErrorStatuses } from "./apiErrorStatuses";

export const uploadCSVFile = async (path: string, file: File, needUserUuid?: boolean) => {
    const {token, userUuid} = getAuthData()
    const formData = new FormData();
    formData.append('file', file);
    formData.append('userUuid', userUuid);
    const userId = needUserUuid ? `/${userUuid}` : '';
    const headers = getHeaders('upload', token)
    try {
        const res = await axios.post(process.env.REACT_APP_API_URL + path + `${userId}`, formData, {
            headers: headers,
            withCredentials: true,
        })
        return res;
    } catch(e) {
        CheckForLogOut(e)
        return e.response
    }
}

const getAuthData = () => {
    const cookies = new Cookies();
    const userUuid = cookies.get('ht');
    const token = cookies.get('token-ht')

    return {token, userUuid}
}

const getHeaders = (headersName: 'default' | 'upload' | 'cron' | 'export', token: string) => {
    switch(headersName) {
        case 'upload':
            return {
                'Cache-Control': 'no-cache',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Origin': 'https://app.heet.ai, https://dev.heet.ai',
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        case 'export':
            return {
                'Cache-Control': 'no-cache',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Origin': 'https://app.heet.ai, https://dev.heet.ai',
                'Content-Type': 'text/csv',
                'Authorization': `Bearer ${token}`
            }
        case 'cron':
            return {
                'Cache-Control': 'no-cache',
                'Access-Control-Allow-Credentials': 'true',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        default: 
            return {
                'Cache-Control': 'no-cache',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Origin': 'https://app.heet.ai, https://dev.heet.ai',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
    }
}

const CheckForLogOut = (e: AxiosError) => {
    const status = e.response.status;
    if(!apiErrorStatuses.includes(status)) {
        logOut()
    }
}

const logOut = () => {
      cleanUpAllStorage();
      deleteAuthCookies()
      window.location.href = '/signIn'
  };

  export const getRequest = async (path: string, needUserUuid?: boolean) => {
    const {token, userUuid} = getAuthData()
    // const isAuth = CheckUserAuth(token, userUuid)
    // if(!isAuth) {
    //     return;
    // }
    const userId = needUserUuid ? `/${userUuid}` : '';
    const headers = getHeaders('default', token)
    try {
        const res = await axios.get(process.env.REACT_APP_API_URL + path + `${userId}`, {
            headers: headers,
            withCredentials: true,
        })
        return res
    } catch (e) {

        if(e.code === 'ERR_NETWORK') {
            return e
        }
        
        CheckForLogOut(e)       
        return e.response
    }
}

export const postRequest = async (path: string, body?: object, needUserUuid?: boolean) => {
    const {token, userUuid} = getAuthData()
    const userId = needUserUuid ? `/${userUuid}` : '';
    const headers = getHeaders('default', token)
    try {
        const res = await axios.post(process.env.REACT_APP_API_URL + path + `${userId}`, body, {
            headers: headers,
            withCredentials: true,
        })
        return res
    } catch (e) {

        if(e.code === 'ERR_NETWORK') {
            return e
        }
        
        CheckForLogOut(e)       
        return e.response
    }
}

export const postRequestExport = async (path: string, body?: object, needUserUuid?: boolean) => {
    const {token, userUuid} = getAuthData()
    // const isAuth = CheckUserAuth(token, userUuid)
    // if(!isAuth) {
    //     return;
    // }
    const userId = needUserUuid ? `/${userUuid}` : '';
    const headers = getHeaders('default', token)
    try {
        const res = await axios.post(process.env.REACT_APP_API_URL + path + `${userId}`, body, {
            headers: headers,
            responseType: 'blob',
            withCredentials: true,
        })
        return res
    } catch (e) {

        if(e.code === 'ERR_NETWORK') {
            return e
        }
        
        CheckForLogOut(e)       
        return e.response
    }
}

export const deleteRequest = async (path: string, needUserUuid?: boolean) => {
    const {token, userUuid} = getAuthData()
    // const isAuth = CheckUserAuth(token, userUuid)
    // if(!isAuth) {
    //     return;
    // }
    const userId = needUserUuid ? `/${userUuid}` : '';
    const headers = getHeaders('default', token)
    try {
        const res = await axios.delete(process.env.REACT_APP_API_URL + path + `${userId}`, {
            headers: headers,
            withCredentials: true,
        })
        return res
    } catch (e) {

        if(e.code === 'ERR_NETWORK') {
            return e
        }
        
        CheckForLogOut(e)       
        return e.response
    }
}
