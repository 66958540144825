import * as React from 'react';
import scss from '../../../AdminPage.module.scss';
import { Box, IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import { TableLoader } from '../../../../../components/tableLoader/TableLoader';
import { usePopupActions } from '../../../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { useAdminPageProxyData } from '../../../../../utils/redux/storeHooks/dataHooks/useAdminPageProxyData';
import moment from 'moment';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import SettingsIcon from '@mui/icons-material/Settings';
import { MonitoringProxy } from '../../../../../types/pages/adminPage/proxy';
import { useAdminPageProxyActions } from '../../../../../utils/redux/storeHooks/actionsHooks/useAdminPageProxyActions';
import DeleteIcon from '@mui/icons-material/Delete';

export const TableDefaultProxiesBody = () => {
  const { defaultProxies, isGetDefaultProxyRequest } = useAdminPageProxyData();
  const { setManageCurrentDefaultProxy } = useAdminPageProxyActions();
  const { setPopUpManageDefaultProxyOpen, setPopUpDeleteDefaultProxyOpen } = usePopupActions();

  const onOpenPopUpManageDefaultProxy = (monitoringProxy: MonitoringProxy) => {
    setManageCurrentDefaultProxy(monitoringProxy)
    setPopUpManageDefaultProxyOpen(true)
  };

  const onOpenPopUpDeleteDefaultProxy = (monitoringProxy: MonitoringProxy) => {
    setManageCurrentDefaultProxy(monitoringProxy)
    setPopUpDeleteDefaultProxyOpen(true)
  };

  return (
    <TableBody sx={{ position: 'relative', color: '#3B2470' }}>
      {isGetDefaultProxyRequest && defaultProxies.length > 0 && <TableLoader />}
      {defaultProxies.map((proxy) => {
        return (
          <TableRow
            hover
            key={proxy.id}
            sx={{
              maxHeight: '64px'
            }}
          >
            <TableCell sx={{ minWidth: 150, maxWidth: 150, padding: '12px 12px' }}>
              <Box className={scss.profileWrapper}>
                {proxy.proxy.split('@').pop()}
              </Box>
            </TableCell>

            <TableCell
              sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}
              align="center">
              {proxy.country_code}
            </TableCell>

            <TableCell
              sx={{ minWidth: 100, maxWidth: 100, padding: '0px 12px' }}
              align="center">
              {moment(proxy.last_verification).format("MMMM D, YYYY h:mm:ss A z")}
            </TableCell>

            <TableCell
              sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}
              align="center">
              {proxy.usage}
            </TableCell>

            <TableCell
              sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}
              align="center">
              {proxy.isValid
                ? <CheckBoxIcon sx={{ color: 'rgba(191, 232, 177, 1)' }} />
                : <DisabledByDefaultIcon sx={{ color: 'rgba(255, 183, 163, 1)' }} />}
            </TableCell>

            <TableCell sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }} align="center">
              <div>
                <IconButton color="primary" aria-label="dm" onClick={() => onOpenPopUpManageDefaultProxy(proxy)}>
                  <SettingsIcon sx={{ color: '#3B2470' }} />
                </IconButton>
              </div>
            </TableCell>

            <TableCell sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }} align="center">
              <div>
                <IconButton color="primary" aria-label="dm" onClick={() => onOpenPopUpDeleteDefaultProxy(proxy)}>
                  <DeleteIcon sx={{ color: '#6f7d9b' }} />
                </IconButton>
              </div>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  )
}