import UserInformation from "../../entities/user_information"

export enum ProxyFlow {
  WEBSHARE = 'WEBSHARE',
  DEFAULT = 'DEFAULT',
}

export enum ProxyPageType {
  USERPROXIES = 'User proxies',
  DEFAULTPROXIES = 'Default proxies'
}

export type MonitoringProxy = {
  id: string;
  proxy: string;
  isValid: boolean;
  country_code: string | null;
  last_verification: Date | null;
  created_at: Date | null;
  isUseForTwoOrMoreUsers?: boolean;
  usage?: number;
};

export type MonitoringUsersProxyEntity = {
  user: Partial<UserInformation>;
  proxy: MonitoringProxy;
};

export type MonitoringUsersProxiesResponse = {
  monitoringProxies: MonitoringUsersProxyEntity[];
  availableWebShareProxies: MonitoringProxy[];
};

export type UpdateProxyResponse = {
  isUpdated: boolean;
  assignedProxy: MonitoringProxy;
};