import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  GenerateIceBreakePayload, ImportSteps, PopupSliceInitialState } from './types';
import { deleteTab, updateTitleTab } from '../newFeedSlice/thunks';
import { loadLeadsSnGet, stopWork } from '../botManagementSlice/thunks';
import { generateIceBreake, sendDM, sendInvitation, uploadCSV } from './thunks';
import { runAutomation } from '../automationPageSlice/thunks';
import { createTeam, deleteTeam, deleteUser, sendEmailCredentials, sendEmailExtension, updateTeam, updateUser } from '../adminPageSlice/thunks';
import { exportLeads, stopWorkUploadSnLink } from '../tableLeadsSlice/thunks';
import { getSubscriptionDetails, upgradePlan } from '../AccounDetailsSlice/thunks';
import { getBlockedModalName } from '../../../pages/accountDetails/getBlockedModalName';
import { ModalsName } from '../../../../types/PPG/ModalsName';
import { getUserInformationFromlocalStorage } from '../../../general/get_set_user_information';
import { addDefaultProxy, deleteDefaultProxy, updateDefaultProxy, updateUserProxy } from '../adminPageProxySlice/thunks';

const initialState: PopupSliceInitialState = {
  //addLeadPopUp state
  isPopupAddLeadOpen: false,  
  //sendMessagePopUp state
  isPopupSendMessageOpen: false,
  iceBreakerMsg: '',
  leadConnectionPosition: 0,
  userFirstLastName: '',
  profileImg: '',
  textOfPost: '',
  isHaveNewActivity: false,
  leadId: 0,
  //import CSV and SN
  isPopUpImportCSVOpen: false,
  importProcessStep: 'first',
  isUploadingCSVProcess: false,
  errorForNoSlots: '',
  isPopUpAddLeadsUseSnOpen: false,
  unvalidLinks: null,
  //RenameTab
  isPopupRenameTabOpen: false,
  isPopUpDeleteTabOpen: false,
  //Bot management
  isPopUpAddBotOpen: false,
  //parsing leads
  isPopUpStopParsingLeadsOpen: false,
  //run automation
  isPopUpRunAutomationOpen: false,
  //Send message
  isMessageGenerating: false,
  isMessageSending: false,
  //Admin Page
  isPopUpManageUserOpen: false,
  isPopUpSendExtensionOpen: false,
  isPopUpSendCredentialOpen: false,
  isPopUpDeleteUserOpen: false,
  isPopUpCreateTeamOpen: false,
  isPopUpDeleteTeamOpen: false,
  isPopUpUpdateTeamOpen: false,
  isPopUpManageUserProxyOpen: false,
  isPopUpManageDefaultProxyOpen: false,
  isPopUpAddNewDefaultProxyOpen: false,
  isPopUpDeleteDefaultProxyOpen: false,
  //LeadsPage
  isPopUpExportLeadsOpen: false,
  //Subscription
  isPopUpUpgradePlanOpen: false,
  isPopUpRestorePasswordOpen: false,
  isPopUpInvoiceSubscriptionEnd: false,
  isPopUpPPGSubscriptionEnd: false,
  isPopUpNewSubscriptionOpen: false,
  isPopUpCancelSubscriptionOpen: false,
};

export const popupSlice = createSlice({
  name: 'popupSlice',
  initialState,
  reducers: {
    resetUnvalidLinks: (state) => {
      state.unvalidLinks = null;
    },
    setPopUpManageUserProxyOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpManageUserProxyOpen = payload;
    },
    setPopUpManageDefaultProxyOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpManageDefaultProxyOpen = payload;
    },
    setPopUpAddNewDefaultProxyOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpAddNewDefaultProxyOpen = payload;
    },
    setPopUpDeleteDefaultProxyOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpDeleteDefaultProxyOpen = payload;
    },  
    setPopUpCancelSubscriptionOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpCancelSubscriptionOpen = payload;
    },
    setPopupNewSubscriptionOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpNewSubscriptionOpen = payload;
    },
    setPopupPPGSubscriptionEndOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpPPGSubscriptionEnd = payload;
    },
    setPopupInvoiceSubsctionEndOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpInvoiceSubscriptionEnd = payload;
    },
    setPopupExportLeadOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpExportLeadsOpen = payload;
    },
    setPopupUpgradePlanOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpUpgradePlanOpen = payload;
    },
    setPopupRestorePasswordOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpRestorePasswordOpen = payload;
    },
    setPopupAddLeadOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopupAddLeadOpen = payload;
    },
    setPopupManageUserOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpManageUserOpen = payload;
    },
    setPopupSendExtensionOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpSendExtensionOpen = payload;
    },
    setPopupSendCredentialOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpSendCredentialOpen = payload;
    },
    setPopupDeleteUserOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpDeleteUserOpen = payload;
    },
    setPopupCreateTeamOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpCreateTeamOpen = payload;
    },
    setPopupUpdateTeamOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpUpdateTeamOpen = payload;
    },
    setPopupDeleteTeamOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpDeleteTeamOpen = payload;
    },
    setPopupSendMessageOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopupSendMessageOpen = payload;
    },
    setIceBreakerMsg: (state, { payload }: PayloadAction<string>) => {
      state.iceBreakerMsg = payload;
    },
    setLeadConnectionPosition: (state, { payload }: PayloadAction<number>) => {
      state.leadConnectionPosition = payload;
    },
    setUserFirstLastName: (state, { payload }: PayloadAction<string>) => {
      state.userFirstLastName = payload;
    },
    setProfileImg: (state, { payload }: PayloadAction<string>) => {
      state.profileImg = payload;
    },
    setTextOfPost: (state, { payload }: PayloadAction<string>) => {
      state.textOfPost = payload;
    },
    setHaveNewActivity: (state, { payload }: PayloadAction<boolean>) => {
      state.isHaveNewActivity = payload;
    },
    setUserId: (state, { payload }: PayloadAction<number>) => {
      state.leadId = payload;
    },
    setPopupImportCSVOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpImportCSVOpen = payload;
    },
    setImportProcessStep: (state, { payload }: PayloadAction<ImportSteps>) => {
      state.importProcessStep = payload;
    },
    setIsUploadingCSVProcess: (state, { payload }: PayloadAction<boolean>) => {
      state.isUploadingCSVProcess = payload;
    },
    setPopupRenameTabOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopupRenameTabOpen = payload;
    },
    setPopUpDeleteTabOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpDeleteTabOpen = payload;
    },
    setPopUpAddBotOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpAddBotOpen = payload;
    },
    setPopUpAddLeadsUseSnOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpAddLeadsUseSnOpen = payload;
    },
    setPopUpStopParsingLeads: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpStopParsingLeadsOpen = payload;
    },
    setPopUpRunAutomation: (state, { payload }: PayloadAction<boolean>) => {
      state.isPopUpRunAutomationOpen = payload;
    },
    setUserDataToSendMessage: (state, { payload }: PayloadAction<GenerateIceBreakePayload>) => {
      const {userFirstLastName, haveNewActivity, id, leadConnectionPosition, profileImg} = payload
      state.userFirstLastName = userFirstLastName;
      state.isHaveNewActivity = haveNewActivity;
      state.leadId = Number(id);
      state.leadConnectionPosition = leadConnectionPosition;
      state.profileImg = profileImg;
      state.iceBreakerMsg = '';
    },
           
  },
  extraReducers: (builder) => {
    //Generate Ice Breake
    builder.addCase(generateIceBreake.pending, (state, action) => {
      const {userFirstLastName, haveNewActivity, id, leadConnectionPosition, profileImg} = action.meta.arg
      state.userFirstLastName = userFirstLastName;
      state.isHaveNewActivity = haveNewActivity;
      state.leadId = Number(id);
      state.leadConnectionPosition = leadConnectionPosition;
      state.profileImg = profileImg;
      state.isMessageGenerating = true;
      state.iceBreakerMsg = '';
    })
    builder.addCase(generateIceBreake.fulfilled, (state, action) => {
      console.log(action.payload)      
      state.iceBreakerMsg = action.payload.text.text;
      state.textOfPost = action.payload.textOfPost
      state.isMessageGenerating = false;
    })
    builder.addCase(generateIceBreake.rejected, (state, action) => {
      state.iceBreakerMsg = '';
      state.textOfPost = ''
      state.isMessageGenerating = false;
    })
    //Upload CSV
    builder.addCase(uploadCSV.pending, (state, action) => {
      state.isUploadingCSVProcess = true;
    })
    builder.addCase(uploadCSV.fulfilled, (state, action) => {
      state.unvalidLinks = action.payload.unvalidLinks;
      state.isUploadingCSVProcess = false;     
    })
    builder.addCase(uploadCSV.rejected, (state, action) => {
      state.isUploadingCSVProcess = false;
    })
    //Rename tab
    builder.addCase(updateTitleTab.fulfilled, (state, action) => {
      state.isPopupRenameTabOpen = false;
    })
    //delete tab
    builder.addCase(deleteTab.fulfilled, (state, action) => {
      state.isPopUpDeleteTabOpen = false;
    })
    //LoadLeadsSn
    builder.addCase(loadLeadsSnGet.fulfilled, (state) => {
      state.isPopUpAddLeadsUseSnOpen = false;    
    })
    //StopWork
    builder.addCase(stopWorkUploadSnLink.fulfilled, (state) => {
      state.isPopUpStopParsingLeadsOpen = false;    
    })
    builder.addCase(stopWork.fulfilled, (state) => {
      state.isPopUpStopParsingLeadsOpen = false;    
    })
    //runAutomation
    builder.addCase(runAutomation.fulfilled, (state, action) => {
      state.isPopUpRunAutomationOpen = false
    })
    //Send invitation
    builder.addCase(sendInvitation.pending, (state, action) => {
      state.isMessageSending = true;
    })
    builder.addCase(sendInvitation.fulfilled, (state, action) => {
      state.isMessageSending = false;
      state.isPopupSendMessageOpen = false;
      state.iceBreakerMsg = '';    
    })
    builder.addCase(sendInvitation.rejected, (state, action) => {
      state.isMessageSending = false;
    })
    //Send DM
    builder.addCase(sendDM.pending, (state, action) => {
      state.isMessageSending = true;
    })
    builder.addCase(sendDM.fulfilled, (state, action) => {
      state.isMessageSending = false;
      state.isPopupSendMessageOpen = false;
      state.iceBreakerMsg = '';    
    })
    builder.addCase(sendDM.rejected, (state, action) => {
      state.isMessageSending = false;
    })
    //Update User
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.isPopUpManageUserOpen = false;
    })
    builder.addCase(updateUser.rejected, (state, action) => {
      state.isPopUpManageUserOpen = false;
    })
    //Delete User
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.isPopUpDeleteUserOpen = false;
    })
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.isPopUpDeleteUserOpen = false;
    })
    //Create Team
    builder.addCase(createTeam.fulfilled, (state, action) => {
      state.isPopUpCreateTeamOpen = false;
    })
    builder.addCase(createTeam.rejected, (state, action) => {
      state.isPopUpCreateTeamOpen = false;
    })
    //Delete Team
    builder.addCase(deleteTeam.fulfilled, (state, action) => {
      state.isPopUpDeleteTeamOpen = false;
    })
    builder.addCase(deleteTeam.rejected, (state, action) => {
      state.isPopUpDeleteTeamOpen = false;
    })
    //Update Team
    builder.addCase(updateTeam.fulfilled, (state, action) => {
      state.isPopUpUpdateTeamOpen = false;
    })
    builder.addCase(updateTeam.rejected, (state, action) => {
      state.isPopUpUpdateTeamOpen = false;
    })
    //Send extension
    builder.addCase(sendEmailExtension.fulfilled, (state, action) => {
      state.isPopUpSendExtensionOpen = false;
    })
    builder.addCase(sendEmailExtension.rejected, (state, action) => {
      state.isPopUpSendExtensionOpen = false;
    })
    //Send credentials
    builder.addCase(sendEmailCredentials.fulfilled, (state, action) => {
      state.isPopUpSendCredentialOpen = false;
    })
    builder.addCase(sendEmailCredentials.rejected, (state, action) => {
      state.isPopUpSendCredentialOpen = false;
    })
    //Export leads
    builder.addCase(exportLeads.fulfilled, (state) => {
      state.isPopUpExportLeadsOpen = false; 
    })
    builder.addCase(exportLeads.rejected, (state) => {
      state.isPopUpExportLeadsOpen = false;
    })
    //Upgrade plan
    builder.addCase(upgradePlan.fulfilled, (state, action) => {
      state.isPopUpUpgradePlanOpen = false;
    })
    //get subscription details
    builder.addCase(getSubscriptionDetails.fulfilled, (state, action) => {
      const {roles} = getUserInformationFromlocalStorage();

      if(typeof roles === 'string' && roles.split(',').includes('Master')) {
        return;
      }
      
      const currentSubscription = action.payload.subscriptionList[0]
      const {isAccountDetailsPage} = action.meta.arg;
      
      if(isAccountDetailsPage) {
        return;
      }

      const modalName = getBlockedModalName(currentSubscription);

      if(modalName) {
        switch(modalName) {
          case ModalsName.INVOICE_SUBSCRIPTION_END:
            state.isPopUpInvoiceSubscriptionEnd = true;
            return;
          case ModalsName.PPG_NEW_SUBSCRIPTION:
            state.isPopUpNewSubscriptionOpen = true;
            return;
          case ModalsName.PPG_SUBSCRIPTION_SUSPENDED:
            state.isPopUpPPGSubscriptionEnd = true;
            return;
          case ModalsName.PPG_INACTIVE_SUBSCRIPTION_MODAL:
            state.isPopUpNewSubscriptionOpen = true;
            return;
          default:
            return;
        }
      }

      if(!modalName) {
        state.isPopUpNewSubscriptionOpen = false;
        state.isPopUpPPGSubscriptionEnd = false;
        state.isPopUpInvoiceSubscriptionEnd = false;
      }
    })
    //Update Users Proxy
    builder.addCase(updateUserProxy.fulfilled, (state, action) => {
      state.isPopUpManageUserProxyOpen = false;
    })
    //Update Default Proxy
    builder.addCase(updateDefaultProxy.fulfilled, (state, action) => {
      state.isPopUpManageDefaultProxyOpen = false;
    })
    //Add Default Proxy
    builder.addCase(addDefaultProxy.fulfilled, (state, action) => {
      state.isPopUpAddNewDefaultProxyOpen = false;
    })
    //Delete Default Proxy
    builder.addCase(deleteDefaultProxy.fulfilled, (state, action) => {
      state.isPopUpDeleteDefaultProxyOpen = false;
    })
  }  
});

export const popupSliceActions = popupSlice.actions;
export const popupReducer = popupSlice.reducer;