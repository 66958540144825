import React, { useState } from 'react';
import styles from "./ManageUserProxy.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { useAdminPageProxyData } from '../../../utils/redux/storeHooks/dataHooks/useAdminPageProxyData';
import { useAdminPageProxyActions } from '../../../utils/redux/storeHooks/actionsHooks/useAdminPageProxyActions';
import { StyledAutocomplete } from '../../styledAutocomplete/StyledAutocomplete';
import { ProxyFlow } from '../../../types/pages/adminPage/proxy';
import { StyledTextField } from '../../styledTextField/StyledTextField';
import { getDefaultUserProxyValue } from '../../../utils/components/manageUserProxy/getDefaultUserProxyValue';

export const ManageUserProxy = () => {
  const { setPopUpManageUserProxyOpen } = usePopupActions();
  const { manageCurrentUserProxy, isUpdateUsersProxiesRequest, availableWebShareProxies } = useAdminPageProxyData();
  const { updateUserProxy, setManageCurrentUserProxy } = useAdminPageProxyActions();
  const user = manageCurrentUserProxy?.user || null;
  const proxy = manageCurrentUserProxy?.proxy || null;
  const [currentProxyFlow, setCurrentProxyFlow] = useState<ProxyFlow>(user?.proxyFlow || null);
  const [selectedWebshareProxy, setSelectedWebshareProxy] = useState<string>('');
  const [defaultProxyValue, setDefaultProxyValue] = useState<string>(getDefaultUserProxyValue(user, proxy));
  const [defaultProxyIncorrect, setdefaultProxyIncorrect] = useState<boolean>(false)

  const closePopUp = () => {
    setPopUpManageUserProxyOpen(false)
    setManageCurrentUserProxy(null)
  }

  const onUpdateUserProxy = () => {
    const defaultValueForProxy = defaultProxyValue || 'none'
    if (proxy.proxy && (selectedWebshareProxy || defaultValueForProxy) && currentProxyFlow) {
      updateUserProxy({
        userId: user.id,
        oldProxy: proxy?.proxy,
        newProxy: selectedWebshareProxy || defaultProxyValue || 'none',
        proxyFlow: currentProxyFlow,
      })
    }
  }

  const onSelectedWebshareProxy = (newValue: string) => {
    if (newValue) {
      const ip = newValue.split(' ')[0]
      const currentWebshareProxy = availableWebShareProxies.find(proxy => proxy.proxy.includes(ip))
      if (currentWebshareProxy) {
        setSelectedWebshareProxy(currentWebshareProxy.proxy)
      }
      return;
    }
    setSelectedWebshareProxy(null)
  }

  const onSelectDefaultProxy = (value: string) => {
    const proxyRegex = /^(?<login>[^:@]+):(?<password>[^:@]+)@(?<ip>\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}):(?<port>\d{1,5})$/;
    const match = value.match(proxyRegex);
    if ((!match && value)) {
      setdefaultProxyIncorrect(true)
    } else {
      setdefaultProxyIncorrect(false)
    }

    setDefaultProxyValue(value)
  }

  const isDisabledUpdateButton = () => {
    if (!currentProxyFlow) {
      return true;
    }

    if (currentProxyFlow === ProxyFlow.WEBSHARE && !selectedWebshareProxy) {
      return true;
    }

    if (currentProxyFlow === ProxyFlow.DEFAULT && defaultProxyIncorrect) {
      return true;
    }

    return false;
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Manage user proxy
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>
        <Box className={styles.content__profile}>
          <Box className={styles.content__profile__userInfoWrapper}>
            <a
              className={styles.content__profile__link}
              href={`https://${styles.dashEntityUrn}`}
              target='_blank'
              rel="noreferrer"
            >
              <Stack direction="row">
                <Avatar
                  alt=""
                  src={user?.profileImg}
                  sx={{ width: '40px', height: '40px' }}
                />
              </Stack>
            </a>
            <Box className={styles.content__profile__userInfo}>
              <Box className={styles.content__profile__userInfo__name}>
                {user?.firstName} {user?.lastName}
              </Box>
              <Box className={styles.content__profile__userInfo__currentProxy}>
                {`Current proxy: ${proxy?.proxy}`}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles.content__inputs}>
          <Box className={styles.content__proxyFlow}>
            <Typography className={styles.content__proxyFlow__title}>
              Select proxy flow:
            </Typography>
            <StyledAutocomplete
              sx={{ width: '100%' }}
              value={currentProxyFlow}
              freeSolo={false}
              onChange={(_, newValue: string | null) => {
                const setValue = newValue as ProxyFlow;
                setCurrentProxyFlow(setValue);
              }}
              options={Object.values(ProxyFlow)}
              disableClearable={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={currentProxyFlow}
                  InputProps={{
                    ...params.InputProps,
                    readOnly: true,
                  }}
                />
              )}
            />
          </Box>

          {currentProxyFlow === ProxyFlow.WEBSHARE && (
            <Box className={styles.content__proxyFlow}>
              <Typography className={styles.content__proxyFlow__title}>
                Select available webshare proxy:
              </Typography>
              <StyledAutocomplete
                sx={{ width: '100%', height: '40px' }}
                freeSolo={false}
                onChange={(_, newValue: string | null) => {
                  onSelectedWebshareProxy(newValue);
                }}
                options={availableWebShareProxies.map(proxy => `${proxy.proxy.split('@').pop()} ${proxy.country_code}`)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={selectedWebshareProxy}
                    InputProps={{
                      ...params.InputProps,
                      readOnly: true,
                    }}
                  />
                )}
              />
            </Box>
          )}

          {currentProxyFlow === ProxyFlow.DEFAULT && (
            <Box className={styles.content__proxyFlow}>
              <Typography className={styles.content__proxyFlow__title}>
                Set custom proxy:
              </Typography>
              <StyledTextField
                sx={{ width: '100%', height: '40px' }}
                value={defaultProxyValue}
                onChange={(e) => onSelectDefaultProxy(e.target.value)}
                error={defaultProxyIncorrect}
                helperText={defaultProxyIncorrect ? 'Proxy string not valid' : ''}
              />
            </Box>
          )}
        </Box>
      </Box>


      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={closePopUp}
          className={styles.footer__cancel}
        >
          Cancel
        </Button>
        <MainActionButton
          title='Update'
          fontWeight={300}
          actionOnClick={onUpdateUserProxy}
          isLoading={isUpdateUsersProxiesRequest}
          isDisabled={isUpdateUsersProxiesRequest || isDisabledUpdateButton()}
        />
      </Box>
    </Box>
  );
};
