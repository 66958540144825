import * as React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { headCellsUsers } from '../../../../utils/pages/adminPage/headCellsUsers';
import { useAdminPageActions } from '../../../../utils/redux/storeHooks/actionsHooks/useAdminPageActions';
import { useAdminPageData } from '../../../../utils/redux/storeHooks/dataHooks/useAdminPageData';
import { useStyles } from '../../../../utils/pages/dashboardLeadsPeoplePage/tableLeads/useStyles';
import { UserOrder, UserOrderBy } from '../../../../types/pages/adminPage/general';
import SortRoundedIcon from '@mui/icons-material/SortRounded';

type Props = {
  scrollUp: () => void;
}

export const TableUsersHeader = ({ scrollUp }: Props) => {
  const { setOrder, setOrderBy, setPage } = useAdminPageActions();
  const { order, orderBy } = useAdminPageData();
  const classes = useStyles();

  const handleRequestSort = (
    property: string,
  ) => {
    const insideOrder = order === UserOrder.ASC ? UserOrder.DESC : UserOrder.ASC
    setOrderBy(property as UserOrderBy)
    setOrder(insideOrder);
    setPage(0)
    scrollUp();
  };

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(property);
  };
  return (
    <>
      <TableHead sx={{
        border: '0px',
        width: '100%',
      }}
      >
        <TableRow sx={{ zIndex: 0 }}>
          {headCellsUsers.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={
                headCell.id === 'firstName'
                  ? 'left'
                  : 'center'
              }
              sx={{
                width: 'auto',
                height: '64px',
                fontFamily: 'Kanit',
                fontSize: '16px',
                lineHeight: '27px',
                color: '#191F2E',
                padding: '0px 12px',
                zIndex: 100,
              }}
            >
              {headCell.disableSorting ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.id)}
                  IconComponent={SortRoundedIcon}
                  classes={{
                    root: orderBy === headCell.id ? classes.sortedLabel : '',
                    icon: orderBy === headCell.id ? classes.sortedIcon : '',
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              )}

            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}
