import { useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useAppDispatch } from '../typedHooks';
import { adminPageProxySliceActions } from '../../slices/adminPageProxySlice/adminPageProxySlice';
import { addDefaultProxy, deleteDefaultProxy, getDefaultProxyList, getUsersProxyList, updateDefaultProxy, updateUserProxy } from '../../slices/adminPageProxySlice/thunks';

const rootActions = {
  ...adminPageProxySliceActions,
  getUsersProxyList,
  getDefaultProxyList,
  updateUserProxy,
  updateDefaultProxy,
  addDefaultProxy,
  deleteDefaultProxy
};

export const useAdminPageProxyActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};