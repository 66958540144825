import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { useEffect, useRef } from 'react';
import { AnimateTab } from '../../../../../components/animateTab/AnimateTab';
import { LoaderSkeleton } from './LoaderSkeleton';
import { TableDefaultProxiesHeader } from './TableDefaultProxiesHeader';
import { TableDefaultProxiesBody } from './TableDefaultProxiesBody';
import { useAdminPageProxyData } from '../../../../../utils/redux/storeHooks/dataHooks/useAdminPageProxyData';

export const TableDefaultProxies = () => {
  const { defaultProxies, isGetDefaultProxyRequest } = useAdminPageProxyData()
  const tableRef = useRef(null);
  const tableContainerRef = useRef(null);

  const handleScroll = () => {
    const tableContainer = tableContainerRef.current;
    if (tableRef.current) {
      const tableRect = tableRef.current.getBoundingClientRect();
      if (tableRect.top <= 20) {
        tableContainer.style.height = `calc(100vh - 25px)`
        return;
      }
      tableContainer.style.height = `100%`
    }
  };

  console.log('default proxies', defaultProxies)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <AnimateTab>
      <Box sx={{ width: '100%', }} >
        <Paper sx={{ width: '100%', boxShadow: 'unset' }}>
          <TableContainer ref={tableContainerRef} sx={{ borderRadius: 2 }}>
            <Table
              ref={tableRef}
              aria-labelledby="tableTitle"
              size={'medium'}
              stickyHeader
            >
              <TableDefaultProxiesHeader />
              {defaultProxies?.length === 0 && isGetDefaultProxyRequest && <LoaderSkeleton />}
              <TableDefaultProxiesBody />
            </Table>

          </TableContainer>
        </Paper>
      </Box >
    </AnimateTab>
  );
}
