import { useAppSelector } from '../typedHooks';

export const useAdminPageProxyData = () => {
  const {
    proxies,
    defaultProxies,
    isGetUsersProxiesRequest,
    isGetDefaultProxyRequest,
    isUpdateUsersProxiesRequest,
    isUpdateDefaultProxyRequest,
    isAddDefaultProxyRequest,
    proxyPageType,
    manageCurrentUserProxy,
    manageCurrentDefaultProxy,
    availableWebShareProxies,
    isDeleteDefaultProxyRequest
  } = useAppSelector((state) => state.adminPageProxyReducer);

  return {
    proxies,
    defaultProxies,
    isGetUsersProxiesRequest,
    isGetDefaultProxyRequest,
    isUpdateUsersProxiesRequest,
    isUpdateDefaultProxyRequest,
    isAddDefaultProxyRequest,
    proxyPageType,
    manageCurrentUserProxy,
    manageCurrentDefaultProxy,
    availableWebShareProxies,
    isDeleteDefaultProxyRequest
  };
};