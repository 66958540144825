import UserInformation from "../../../types/entities/user_information";
import { MonitoringProxy, ProxyFlow } from "../../../types/pages/adminPage/proxy";

export const getDefaultUserProxyValue = (user:Partial<UserInformation> | null, proxy: MonitoringProxy | null) => {
  if(!proxy || !user) {
    return ''
  }

  if(user.proxyFlow === ProxyFlow.DEFAULT) {
    return proxy.proxy === 'none' ? '' : proxy.proxy
  } else {
    return ''
  }
}