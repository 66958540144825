import { HeadCell } from "../../../types/pages/dashboardLeadsPeoplePage/headCell";

export const headCellsUserProxies: readonly HeadCell[] = [
  {
    id: 'user',
    numeric: false,
    disablePadding: false,
    label: 'User',
    disableSorting: false,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 150,
    minWidth: 150,
  },
  {
    id: 'userLocation',
    numeric: false,
    disablePadding: false,
    label: 'User location',
    disableSorting: false,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  },
  
  {
    id: 'proxyFlow',
    numeric: true,
    disablePadding: false,
    label: 'proxyFlow',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  },
  {
    id: 'proxyIp',
    numeric: true,
    disablePadding: false,
    label: 'Proxy IP',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  },
  {
    id: 'proxyLocation',
    numeric: true,
    disablePadding: false,
    label: 'Proxy location',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  },
  {
    id: 'isValid',
    numeric: true,
    disablePadding: false,
    label: 'Is valid',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  },
  {
    id: 'isShared',
    numeric: true,
    disablePadding: false,
    label: 'Is shared',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  },
  {
    id: 'setting',
    numeric: true,
    disablePadding: false,
    label: '',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  }
];