import { getRequest, postRequest } from "../../../general/https";
import { checkErrorStatus } from "../../../general/checkErrorStatus";
import { CreateManualSubscriptionPayload, CreateManualSubscriptionResponse, CreateTeamPayload, CreateTeamResponse, DeleteManualSubscriptionPayload, DeleteManualSubscriptionResponse, DeleteTeamPayload, DeleteTeamResponse, DeleteUserPayload, DeleteUserResponse, DeleteWrongAdditionalInfoPayload, DeleteWrongAdditionalInfoResponse, GeneratePasswordPayload, GeneratePasswordResponse, GetTeamsListResponse, GetUsersListPayload, GetUsersListResponse, MasterLoginPayload, MasterLogInResponse, SendEmailCredentialsPayload, SendEmailCredentialsResponse, SendEmailExtensionPayload, SendEmailExtensionResponse, UpdateTeamPayload, UpdateTeamResponse, UpdateUserPayload, UpdateUserResponse } from "./types";
import { createAsyncThunk } from "@reduxjs/toolkit";

const sliceMainUrl = '/admin-page'

export const getUsersList = createAsyncThunk<GetUsersListResponse, GetUsersListPayload>(
  'adminPageSlice/getUsersList',
  async (payload, {rejectWithValue}) => {
    try {
      const {order, orderBy, skip, take} = payload;
      const response = await getRequest(`${sliceMainUrl}/get-users/${order}/${orderBy}/${skip}/${take}/get-all-users`, false)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateUser = createAsyncThunk<UpdateUserResponse, UpdateUserPayload>(
  'adminPageSlice/updateUser',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`${sliceMainUrl}/update-user`, payload)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const deleteUser = createAsyncThunk<DeleteUserResponse, DeleteUserPayload>(
  'adminPageSlice/deleteUser',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`${sliceMainUrl}/delete-user`, payload)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getTeamsList = createAsyncThunk<GetTeamsListResponse>(
  'adminPageSlice/getTeamList',
  async (_, {rejectWithValue}) => {
    try {
      const response = await getRequest(`${sliceMainUrl}/get-all-teams`, true)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const sendEmailExtension = createAsyncThunk<SendEmailExtensionResponse, SendEmailExtensionPayload>(
  'adminPageSlice/sendEmailExtension',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`${sliceMainUrl}/send-email-extension`, payload)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const generatePassword = createAsyncThunk<GeneratePasswordResponse, GeneratePasswordPayload>(
  'adminPageSlice/generatePassword',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`${sliceMainUrl}/generate-password`, payload)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const sendEmailCredentials = createAsyncThunk<SendEmailCredentialsResponse, SendEmailCredentialsPayload>(
  'adminPageSlice/sendEmailCredentials',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`${sliceMainUrl}/send-email-credentials`, payload)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const createTeam = createAsyncThunk<CreateTeamResponse, CreateTeamPayload>(
  'adminPageSlice/createTeam',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`${sliceMainUrl}/create-team`, payload)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateTeam = createAsyncThunk<UpdateTeamResponse, UpdateTeamPayload>(
  'adminPageSlice/updateTeam',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`${sliceMainUrl}/update-team`, payload)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const deleteTeam = createAsyncThunk<DeleteTeamResponse, DeleteTeamPayload>(
  'adminPageSlice/deleteTeam',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`${sliceMainUrl}/delete-team`, payload)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const deleteAdditionalWrongInfo = createAsyncThunk<DeleteWrongAdditionalInfoResponse, DeleteWrongAdditionalInfoPayload>(
  'adminPageSlice/deleteAdditionalWrongInfo',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`${sliceMainUrl}/delete-wrong-additional-info`, payload)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const createManualSubscription = createAsyncThunk<CreateManualSubscriptionResponse, CreateManualSubscriptionPayload>(
  'adminPageSlice/createManualSubscription',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`${sliceMainUrl}/${payload.userId}/create-manual-subscription`, {}, false)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const deleteManualSubscription = createAsyncThunk<DeleteManualSubscriptionResponse, DeleteManualSubscriptionPayload>(
  'adminPageSlice/deleteManualSubscription',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`${sliceMainUrl}/${payload.userId}/delete-manual-subscription`, {}, false)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const masterLogIn = createAsyncThunk<MasterLogInResponse, MasterLoginPayload>(
  'adminPageSlice/masterLogin',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`/auth/master-login`, payload)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

