import React, { useEffect } from "react";
import { FunctionComponent } from "react";
import styles from "./AdminPage.module.scss";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { TabsType } from "../../types/general/pagesTabs";
import { useAdminPageActions } from "../../utils/redux/storeHooks/actionsHooks/useAdminPageActions";
import { pagesTabs } from "../../utils/pages/adminPage/tabs";
import { PagesTabs } from "../../components/pagesTabs/PagesTabs";
import Header from "../../components/header/Header";
import { AdminPageActions } from "./features/AdminPageActions";
import { useAdminPageData } from "../../utils/redux/storeHooks/dataHooks/useAdminPageData";
import { getSkip } from "../../utils/general/getPageFromTo";

export const AdminPage: FunctionComponent = () => {
  const { getUsersList, getTeamsList } = useAdminPageActions();
  const { order, orderBy, page, rowsPerPage } = useAdminPageData();

  useEffect(() => {
    getUsersList({
      order,
      orderBy,
      skip: getSkip(page, rowsPerPage),
      take: rowsPerPage
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy, page, rowsPerPage]);

  useEffect(() => {
    getTeamsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <Box className={styles.pageContainer}>
        <PagesTabs tabs={pagesTabs} tabsType={TabsType.regular} />
        <AdminPageActions />
        <Outlet />
      </Box>
    </>

  );
};
