import { getRequest, postRequest } from "../../../general/https";
import { checkErrorStatus } from "../../../general/checkErrorStatus";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AddDefaultProxyPayload, DeleteDefaultProxyPayload, DeleteDefaultProxyResponse, GetDefaultProxyListResponse, UpdateDefaultProxyPayload, UpdateUserProxyPayload } from "./types";
import { MonitoringUsersProxiesResponse, UpdateProxyResponse } from "../../../../types/pages/adminPage/proxy";

const sliceMainUrl = '/proxy'

export const getUsersProxyList = createAsyncThunk<MonitoringUsersProxiesResponse>(
  'adminPageProxySlice/getUsersProxyList',
  async (_, {rejectWithValue}) => {
    try {
      const response = await getRequest(`${sliceMainUrl}/get-monitorig-proxies`, false)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getDefaultProxyList = createAsyncThunk<GetDefaultProxyListResponse>(
  'adminPageProxySlice/getDefaultProxyList',
  async (_, {rejectWithValue}) => {
    try {
      const response = await getRequest(`${sliceMainUrl}/get-default-proxies`, false)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateUserProxy = createAsyncThunk<UpdateProxyResponse, UpdateUserProxyPayload>(
  'adminPageProxySlice/updateUserProxy',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`${sliceMainUrl}/update-user-proxy`, payload)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateDefaultProxy = createAsyncThunk<UpdateProxyResponse, UpdateDefaultProxyPayload>(
  'adminPageProxySlice/updateDefaultProxy',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`${sliceMainUrl}/update-default-proxy`, payload)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const addDefaultProxy = createAsyncThunk<UpdateProxyResponse, AddDefaultProxyPayload>(
  'adminPageProxySlice/addDefaultProxy',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`${sliceMainUrl}/add-default-proxy`, payload)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const deleteDefaultProxy = createAsyncThunk<DeleteDefaultProxyResponse, DeleteDefaultProxyPayload>(
  'adminPageProxySlice/deleteDefaultProxy',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`${sliceMainUrl}/delete-default-proxy`, payload)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
